// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  Button,
  TextP,
  Layout,
  LinkedButton,
  MarkdownPs,
  Section,
  SectionFeatures,
  SectionTestimonialSquare,
  SectionScreenshot,
  TabMenu,
  TextHeading,
} from '../components';
import config from '../config';
import { Dimen } from '../constants';
import { unwrapConnection } from '../util';

import type { PageFeaturesGuestQuery } from '../__generated__/PageFeaturesGuestQuery';

type Props = $ReadOnly<{|
  data: PageFeaturesGuestQuery,
|}>;

type State = {|
  copySuccess: boolean,
|};

export default class PageGuest extends React.Component<Props, State> {
  _inputRef = React.createRef();

  state = {
    copySuccess: false,
  };

  _copyToClipboard = () => {
    const node = this._inputRef.current;
    if (node) {
      node.select();
      document.execCommand('copy');
      this.setState({ copySuccess: true });
    }
  };

  render() {
    const {
      data: {
        page,
        allDatoCmsSectionScreenshot,
        allDatoCmsFeatureSection,
        testimonialLeft,
        testimonialRight,
      },
    } = this.props;
    const { copySuccess } = this.state;

    if (!page) throw new Error('PageFeaturesGuest missing from CMS');
    if (!allDatoCmsFeatureSection)
      throw new Error('Missing allDatoCmsFeatureSection');

    if (!allDatoCmsSectionScreenshot)
      throw new Error('Missing allDatoCmsSectionScreenshot');
    const unwrappedScreenshotSections = unwrapConnection(
      allDatoCmsSectionScreenshot
    );
    const screenshotSections = {};
    [1, 2].forEach(n => {
      const key = `guestFeatures_${n}`;
      screenshotSections[key] = unwrappedScreenshotSections.find(
        b => b.identifier === key
      );
    });

    const unwrappedFeaturesSection = unwrapConnection(allDatoCmsFeatureSection);
    const feauturesSections = {};
    [1, 2].forEach(n => {
      const key = `guest_${n}`;
      feauturesSections[key] = unwrappedFeaturesSection.find(
        b => b.identifier === key
      );
    });

    return (
      <Layout>
        <HelmetDatoCms seo={page.seoMetaTags} />
        <TabMenu
          links={[
            { url: '/host', label: 'View Host Features', highlight: false },
            {
              url: '/guest',
              label: 'View Guest Features',
              highlight: true,
            },
          ]}
        />
        <Section>
          <StyledLeadingText>
            <TextHeading level={1} tag="h1">
              {page.heading}
            </TextHeading>
            {page.leadingText && <MarkdownPs html={page.leadingText} />}
            <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
          </StyledLeadingText>
        </Section>
        <Testimonials>
          {testimonialLeft && (
            <SectionTestimonialSquare
              testimonial={testimonialLeft}
              backgroundColor="darkBlue"
            />
          )}
          {testimonialRight && (
            <SectionTestimonialSquare
              testimonial={testimonialRight}
              backgroundColor="darkBlue"
            />
          )}
        </Testimonials>
        {screenshotSections.guestFeatures_1 && (
          <SectionScreenshot
            screenshot={screenshotSections.guestFeatures_1}
            imageSrc="/img/screenshots/guest_1.png"
            effectSrc="/img/screenshots/effect_yellow.svg"
            picAfter
            backgroundColor="yellow"
          />
        )}
        {feauturesSections.guest_1 && (
          <SectionFeatures featuresSection={feauturesSections.guest_1} />
        )}
        {screenshotSections.guestFeatures_2 && (
          <SectionScreenshot
            screenshot={screenshotSections.guestFeatures_2}
            backgroundImageSrc="/img/section_background_guest_2.jpg"
            invert
            picAfter
          />
        )}
        {feauturesSections.guest_2 && (
          <SectionFeatures featuresSection={feauturesSections.guest_2} />
        )}
        <Section>
          <StyledLeadingText>
            <TextHeading level={1} tag="h1">
              {page.bottomHeading}
            </TextHeading>
            {page.bottomLeadingText && (
              <MarkdownPs html={page.bottomLeadingText} />
            )}
            {/*
            <TextP>{page.inviteTitle}</TextP>
            <StyledInput
              type="text"
              value={`${config.MARKETING_ROOT}/host`}
              innerRef={this._inputRef}
              readOnly
            />
            <Button
              label={copySuccess ? 'Copied' : 'Click to Copy'}
              onClick={this._copyToClipboard}
            />
            */}
            <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
          </StyledLeadingText>
        </Section>
        <StyledSection coverUrl="/img/section_background_guest_3.jpg" />
      </Layout>
    );
  }
}

export const query = graphql`
  query PageFeaturesGuestQuery {
    page: datoCmsPageFeaturesGuest {
      heading
      leadingText
      buttonLabel
      buttonLink
      bottomHeading
      bottomLeadingText
      inviteTitle
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    testimonialLeft: datoCmsTestimonial(identifier: { eq: "guest_1" }) {
      ...SectionTestimonialSquare_testimonial
    }

    testimonialRight: datoCmsTestimonial(identifier: { eq: "guest_2" }) {
      ...SectionTestimonialSquare_testimonial
    }

    allDatoCmsSectionScreenshot(
      filter: { identifier: { regex: "/^guestFeatures_/" } }
    ) {
      edges {
        node {
          identifier
          ...SectionScreenshot_screenshot
        }
      }
    }

    allDatoCmsFeatureSection(filter: { identifier: { regex: "/^guest_/" } }) {
      edges {
        node {
          identifier
          ...FeatureSection_featureSectionConnection
        }
      }
    }
  }
`;

const Testimonials = styled('div')`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledSection = styled(Section)`
  height: ${rem(Dimen.headerHeight * 5)};
`;

const StyledInput = styled('input')`
  display: block;
  font-size: ${rem(24)};
  border: 0;
  outline: 0;
  margin: ${rem(20)} 0;
  padding: ${rem(20)} 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
`;
